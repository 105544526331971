.p-title {
  font-size: 1.25rem;
  font-weight: 600;
}
.faqs-ol {
  margin-top: 16px;
  margin-bottom: 16px;
}

.faqs-ol > li {
  margin-top: 16px;
}
